import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import _ from "lodash"
import log from "loglevel"
import React from "react"
import ButtonList from "../components/button-list"
import CompatibleProducts from "../components/compatible-products"
import Dimensions from "../components/dimensions"
import FAQs from "../components/faqs"
import Layout from "../components/layout"
import Notes from "../components/notes"
import PhaseOutWarning from "../components/phase-out-warning"
import RefrigerantSeries from "../components/refrigerant-series"
import TitleWithCopyLink from "../components/title-copy-link"
import { numberWithCommas } from "../utils/formatting"

const SkyAir = ({ data, pageContext }) => {
  const oduProduct = data.oduProduct.nodes[0] || {}
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategoryId = pageContext.subcategoryId
  const iduProduct = data.iduProduct.nodes[0] || {}
  const faqs = data.faqs.nodes

  let image =
    data.oduImages.edges.length &&
    data.oduImages.edges.find(
      edge =>
        edge.node.parent.name.includes(oduProduct.productSeries) &&
        edge.node.parent.name.includes(iduProduct.productSeries)
    )
  if (!image) {
    image = data.oduImages.edges.length && data.oduImages.edges[0]
  }
  if (image) {
    oduProduct.image = image.node.parent
  }

  iduProduct.image =
    data.iduImages.edges.length && data.iduImages.edges[0].node.parent

  oduProduct.models = oduProduct.models.filter(model =>
    model.productSeries
      .split(" + ")
      .some(series => series === iduProduct.productSeries)
  )

  oduProduct.models.forEach(model => {
    if (model.submittalSheet) {
      model.submittalSheet.path = (
        (
          data.submittalSheet.edges.find(
            edge => edge.node.name === model.modelNumber.replace(" + ", "")
          ) || {}
        ).node || {}
      ).publicURL
    }
  })

  const compatibleControls = data.compatibleControls.nodes || []
  const compatibleRemotes = data.compatibleRemotes.nodes || []

  log.info(oduProduct, iduProduct, compatibleControls, pageContext)

  const mainTableHeadings = {
    modelNumber: "modelNumber",
    capacity: "capacity",
    ports: "ports",
    heating: ["heating1", "heating2", "heating3", "heating4"],
    heating1: "heating1",
    heating2: "heating2",
    heating3: "heating3",
    heating4: "heating4",
    piping: [
      "linesetSize",
      "pipingLengthMax",
      "pipingLengthVertical",
      "pipingLength",
    ],
    linesetSize: "linesetSize",
    pipingLengthMax: "pipingLengthMax",
    pipingLengthVertical: "pipingLengthVertical",
    pipingLength: "pipingLength",
    IDUCFM: "IDUCFM",
    IDUCFM2: "IDUCFM2",
    IDUCFM3: "IDUCFM3",
    cooling: "cooling",
    powerSupply: "powerSupply",
    energyStar: "energyStar",
  }

  const headingsColSpan = Object.keys(mainTableHeadings).reduce((obj, key) => {
    obj[key] = _.isArray(mainTableHeadings[key])
      ? oduProduct.models.length
        ? Math.max.apply(
            null,
            oduProduct.models.map(model =>
              mainTableHeadings[key].reduce(
                (acc, _key) => acc + (model[_key] ? 1 : 0),
                0
              )
            )
          )
        : 0
      : oduProduct.models.some(model => !!model[mainTableHeadings[key]])
      ? 1
      : 0
    return obj
  }, {})

  // log.info(headingsColSpan)

  return (
    <Layout title={oduProduct.name} navItems={pageContext.categories || []}>
      <div className="uk-section-xsmall">
        <div className="uk-container uk-container-large">
          <ul className="uk-breadcrumb">
            <li>
              <span>{category.name}</span>
            </li>
            <li>
              <Link
                to={`/category/${category._id}/sub/${selectedSubcategoryId}`}
              >
                {oduProduct.category}
              </Link>
            </li>
            <li>
              <span>{oduProduct.productSeries}</span>
            </li>
          </ul>
          <div className="uk-section-xsmall">
            <div className="uk-container uk-container-large uk-text-center">
              {/* <h2>{category.name}</h2> */}
              <ButtonList
                items={subcategories}
                parentId={category._id}
                selectedItemId={selectedSubcategoryId}
              ></ButtonList>
            </div>
          </div>
          <div className="product uk-section-xsmall">
            <h1 className="uk-text-center white-space-pre-line">
              {(oduProduct.models || []).length
                ? oduProduct.models[0].name
                : oduProduct.name}
            </h1>
            {/* <h4 className="uk-text-center">{product.subcategory}</h4> */}

            <p className="uk-text-center">
              <span className="uk-text-bold">{iduProduct.capacityText}</span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span className="uk-text-bold">Model: </span>
              {`${oduProduct.productSeries}${
                iduProduct ? ` + ${iduProduct.productSeries}` : ""
              }`}
            </p>
            <RefrigerantSeries value={oduProduct.refrigerantSeries} />
            <PhaseOutWarning
              categoryId={category._id}
              warning={oduProduct.phaseOutWarning}
              replacement={oduProduct.replacementProductSeries}
            />

            <Dimensions
              oduProduct={oduProduct}
              iduProduct={iduProduct}
            ></Dimensions>

            <div className="uk-section-xsmall">
              <h3 className="uk-text-bold uk-text-center"></h3>
              <div className="uk-overflow-auto">
                <table className="uk-table uk-table-striped uk-text-center uk-table-small">
                  <thead className="uk-text-center">
                    <tr>
                      {headingsColSpan["modelNumber"] ? <th></th> : <></>}
                      {headingsColSpan["capacity"] ? <th></th> : <></>}
                      {headingsColSpan["ports"] ? <th></th> : <></>}
                      {headingsColSpan["IDUCFM"] ? <th></th> : <></>}
                      {headingsColSpan["IDUCFM2"] ? <th></th> : <></>}
                      {headingsColSpan["IDUCFM3"] ? <th></th> : <></>}
                      {headingsColSpan["cooling"] ? <th></th> : <></>}
                      {headingsColSpan["heating"] ? (
                        <th
                          className="th-primary"
                          colSpan={headingsColSpan["heating"]}
                        >
                          Heating Capacities (BTU/HR)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["piping"] ? (
                        <th
                          className="th-primary"
                          colSpan={headingsColSpan["piping"]}
                        >
                          Piping
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["powerSupply"] ? <th></th> : <></>}
                      {headingsColSpan["energyStar"] ? <th></th> : <></>}
                    </tr>
                    <tr>
                      {headingsColSpan["modelNumber"] ? (
                        <th className="text-dark">Model Number</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["capacity"] ? (
                        <th className="text-dark">Nominal Size (MBH)</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["ports"] ? (
                        <th className="text-dark">
                          Number of Ports (max IDU count)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["IDUCFM"] ? (
                        <th className="text-dark">
                          Indoor Unit CFM (High/Low)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["IDUCFM2"] ? (
                        <th className="text-dark">
                          Indoor Unit CFM (High/Medium/Low)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["IDUCFM3"] ? (
                        <th className="text-dark">
                          Indoor Unit CFM (High/Medium/Low/Super Low)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["cooling"] ? (
                        <th className="text-dark">
                          Nominal Cooling @95°F (BTU/HR)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["heating1"] ? (
                        <th className="text-dark text-no-wrap">43°FWB </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["heating2"] ? (
                        <th className="text-dark text-no-wrap">5°FWB</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["heating3"] ? (
                        <th className="text-dark text-no-wrap">-4°FWB</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["heating4"] ? (
                        <th className="text-dark text-no-wrap">-13°FWB</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["linesetSize"] ? (
                        <th className="text-dark">Lineset Size</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["pipingLengthMax"] ? (
                        <th className="text-dark">Max Total Length (ft)</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["pipingLengthVertical"] ? (
                        <th className="text-dark">Max Vertical Length (ft)</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["pipingLength"] ? (
                        <th className="text-dark">
                          Max Vertical Length IDU to IDU (ft)
                        </th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["powerSupply"] ? (
                        <th className="text-dark">Power</th>
                      ) : (
                        <></>
                      )}
                      {headingsColSpan["energyStar"] ? (
                        <th className="text-dark">Energy Star Certified</th>
                      ) : (
                        <></>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {oduProduct.models.map((model, i) => (
                      <tr key={`main_table_tr_${i}`}>
                        <td>{model.modelNumber}</td>
                        <td>{model.capacity}</td>
                        {model.ports ? (
                          <td>{model.ports}</td>
                        ) : headingsColSpan["ports"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.IDUCFM ? (
                          <td className="text-no-wrap">{model.IDUCFM}</td>
                        ) : headingsColSpan["IDUCFM"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.IDUCFM2 ? (
                          <td className="text-no-wrap">{model.IDUCFM2}</td>
                        ) : headingsColSpan["IDUCFM2"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.IDUCFM3 ? (
                          <td className="text-no-wrap">{model.IDUCFM3}</td>
                        ) : headingsColSpan["IDUCFM3"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.cooling ? (
                          <td>{numberWithCommas(model.cooling)}</td>
                        ) : headingsColSpan["cooling"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.heating1 ? (
                          <td>{numberWithCommas(model.heating1)}</td>
                        ) : headingsColSpan["heating1"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.heating2 ? (
                          <td>{numberWithCommas(model.heating2)}</td>
                        ) : headingsColSpan["heating2"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.heating3 ? (
                          <td>{numberWithCommas(model.heating3)}</td>
                        ) : headingsColSpan["heating3"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.heating4 ? (
                          <td>{numberWithCommas(model.heating4)}</td>
                        ) : headingsColSpan["heating4"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.linesetSize ? (
                          <td className="text-no-wrap">{model.linesetSize}</td>
                        ) : headingsColSpan["linesetSize"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.pipingLengthMax ? (
                          <td>{model.pipingLengthMax}</td>
                        ) : headingsColSpan["pipingLengthMax"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.pipingLengthVertical ? (
                          <td>{model.pipingLengthVertical}</td>
                        ) : headingsColSpan["pipingLengthVertical"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.pipingLength ? (
                          <td>{model.pipingLength}</td>
                        ) : headingsColSpan["pipingLength"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.powerSupply ? (
                          <td className="text-no-wrap">{model.powerSupply}</td>
                        ) : headingsColSpan["powerSupply"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                        {model.energyStar ? (
                          <td>{model.energyStar}</td>
                        ) : headingsColSpan["energyStar"] ? (
                          <td></td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {(oduProduct.notes || []).concat(iduProduct.notes || [])
                .length ? (
                <div className="">
                  <br />
                  {/* <h3 className="uk-text-bold uk-text-center">Notes</h3> */}
                  <div className="uk-text-meta">
                    <ul>
                      {(oduProduct.notes || [])
                        .concat(iduProduct.notes || [])
                        .map((feature, i) => (
                          <li key={`note_${i}`}>{feature}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="uk-flex uk-flex-wrap uk-grid-small" data-uk-grid>
              <div
                className="uk-width-1-2@s uk-width-1-1@s"
                style={{ padding: "0 20px" }}
              >
                <Notes
                  title={"Outdoor Unit Features"}
                  items={oduProduct.features}
                />
              </div>
              <div
                className="uk-width-1-2@s uk-width-1-1@s"
                style={{ padding: "0 20px" }}
              >
                <Notes
                  title={"Indoor Unit Features"}
                  items={iduProduct.features}
                />
              </div>
            </div>

            <Notes
              title={"Other Considerations"}
              items={oduProduct.considerations}
            />

            {_.uniq(
              oduProduct.models
                .filter(model => !!model.IDUMCAMOP || !!model.ODUMCAMOP)
                .map(model => model.capacity)
            ).length ? (
              <div className="uk-section-xsmall">
                <TitleWithCopyLink title={"Electrical Information"} type="h3" />

                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-striped  uk-table-small">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th
                          className="uk-text-center th-primary"
                          colSpan={
                            _.uniq(
                              oduProduct.models
                                .filter(
                                  model =>
                                    !!model.IDUMCAMOP || !!model.ODUMCAMOP
                                )
                                .map(model => model.capacity)
                            ).length
                          }
                        >
                          MCA (A) / MOP (A)
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th className="text-dark">Type</th>
                        <th className="text-dark">Voltage</th>
                        {_.uniq(
                          oduProduct.models
                            .filter(
                              model => !!model.IDUMCAMOP || !!model.ODUMCAMOP
                            )
                            .map(model => model.capacity)
                        ).map((cap, i) => (
                          <th className="text-dark" key={`${cap}_${i}`}>
                            {cap} MBH
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {[0, 1].map((outdoor, i) => {
                        const models = oduProduct.models.filter(model =>
                          outdoor ? model.ODUMCAMOP : model.IDUMCAMOP
                        )
                        if (!models.length) {
                          return
                        }
                        const caps = _.uniq(
                          oduProduct.models
                            .filter(
                              model => !!model.IDUMCAMOP || !!model.ODUMCAMOP
                            )
                            .map(model => model.capacity)
                        )
                        return (
                          <tr key={`mca_row_${i}`}>
                            <td>{outdoor ? "Outdoor unit" : "Indoor unit"}</td>
                            <td>
                              {models.length &&
                                (outdoor
                                  ? models[0].ODUPowerSupply
                                  : models[0].IDUPowerSupply)}
                            </td>
                            {caps.map((capacity, j) => {
                              const found = models.find(
                                _model =>
                                  (outdoor
                                    ? _model.ODUMCAMOP
                                    : _model.IDUMCAMOP) &&
                                  _model.capacity === capacity
                              )
                              return found ? (
                                <td key={`mca_cap_row_${j}`}>
                                  {outdoor ? found.ODUMCAMOP : found.IDUMCAMOP}
                                </td>
                              ) : undefined
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <></>
            )}

            <CompatibleProducts
              title="Compatible Controls"
              category="Thermostats"
              products={compatibleControls}
              images={data.controlsImages.edges}
            ></CompatibleProducts>

            <CompatibleProducts
              title="Compatible Remotes"
              products={compatibleRemotes}
              images={data.controlsImages.edges}
            ></CompatibleProducts>

            <CompatibleProducts
              title="Compatible Adaptors"
              category="Adaptors"
              products={compatibleControls}
              images={data.controlsImages.edges}
            ></CompatibleProducts>

            <div className="uk-section-xsmall">
              <TitleWithCopyLink title={"Submittal Data Sheets"} type="h3" />

              <div className="uk-overflow-auto">
                <table
                  className="uk-table uk-table-striped  uk-table-small same-width-td uk-margin-auto"
                  style={{ maxWidth: "400px" }}
                >
                  <thead className="thead-primary">
                    <tr>
                      <th></th>
                      {_.uniq(
                        oduProduct.models.map(model => model.powerSupply)
                      ).map((powerSupply, i) => (
                        <th key={`ps_${i}`}>{powerSupply}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_.uniq(oduProduct.models.map(model => model.capacity)).map(
                      (capacity, i) => {
                        const electricals = _.uniq(
                          oduProduct.models.map(model => model.powerSupply)
                        )
                        return (
                          <tr key={`submittalSheets_row_${i}`}>
                            <td className="uk-text-bold text-dark">
                              {capacity}
                            </td>
                            {electricals.map((electrical, j) => {
                              const model = oduProduct.models.find(
                                _model =>
                                  _model.powerSupply === electrical &&
                                  _model.capacity === capacity &&
                                  _model.submittalSheet &&
                                  _model.submittalSheet.path
                              )
                              return (
                                <td
                                  className="text-no-wrap"
                                  key={`submittalSheets_col_${j}`}
                                >
                                  {((model || {}).submittalSheet || {}).path ? (
                                    <OutboundLink
                                      href={model.submittalSheet.path}
                                      target="_blank"
                                    >
                                      {model.modelNumber}
                                    </OutboundLink>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <FAQs items={faqs} productSeries={oduProduct.productSeries}></FAQs>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProductAndImage(
    $oduId: String
    $iduId: String
    $oduImgId: String
    $iduImgId: String
    $categoryRegex: String
    $subcategoryRegex: String
    $controlsCompatibility: String
    $remoteCompatibility: String
  ) {
    oduImages: allImageSharp(
      filter: { original: { src: { regex: $oduImgId } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    iduImages: allImageSharp(
      filter: { original: { src: { regex: $iduImgId } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    submittalSheet: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    faqs: allFaqsJson(
      filter: {
        categoryId: { regex: $categoryRegex }
        subcategoryId: { regex: $subcategoryRegex }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    oduProduct: allProductsJson(filter: { _id: { eq: $oduId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        image
        name
        productId
        productSeries
        description
        features
        notes
        considerations
        productImage {
          fileName
          url
        }
        productType
        subcategory
        width
        refrigerantSeries
        replacementProductSeries
        phaseOutWarning
        models {
          capacity
          CFM
          MCA
          MCAMOP
          MOP
          dimensions
          cooling
          heating
          image {
            url
            fileName
          }
          maxSound
          modelNumber
          moduleCapacity
          moduleType
          name
          operatingSound
          ports
          powerSupply
          productSeries
          availableUS
          availableCA
          estimatedHeating
          coolingEfficiency
          heatingEfficiency
          coolingEfficiencySEER
          heatingEfficiencyHSPF
          heatingEfficiencyEWT
          IDUProductSeries
          ODUProductSeries
          ODUDimensions
          IDUDimensions
          airflow
          waterflow
          weight
          IDUCFM
          IDUCFM2
          IDUCFM3
          heating1
          heating2
          heating3
          heating4
          linesetSize
          pipingLengthMax
          pipingLengthVertical
          pipingLength
          pipingLengthSum
          energyStar
          IDUPowerSupply
          IDUMCAMOP
          ODUPowerSupply
          ODUMCAMOP
          submittalSheet {
            url
            fileName
            type
          }
        }
      }
    }
    iduProduct: allProductsJson(filter: { _id: { eq: $iduId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        image
        name
        productId
        productSeries
        description
        features
        notes
        considerations
        productImage {
          fileName
          url
        }
        productType
        subcategory
        width
        refrigerantSeries
        models {
          capacity
          CFM
          MCA
          MCAMOP
          MOP
          dimensions
          cooling
          heating
          image {
            url
            fileName
          }
          maxSound
          modelNumber
          moduleCapacity
          moduleType
          name
          operatingSound
          ports
          powerSupply
          productSeries
          availableUS
          availableCA
          estimatedHeating
          coolingEfficiency
          heatingEfficiency
          coolingEfficiencySEER
          heatingEfficiencyHSPF
          heatingEfficiencyEWT
          IDUProductSeries
          ODUProductSeries
          ODUDimensions
          IDUDimensions
          airflow
          waterflow
          weight
          IDUCFM
          IDUCFM2
          IDUCFM3
          heating1
          heating2
          heating3
          heating4
          linesetSize
          pipingLengthMax
          pipingLengthVertical
          pipingLength
          pipingLengthSum
          energyStar
          IDUPowerSupply
          IDUMCAMOP
          ODUPowerSupply
          ODUMCAMOP
          submittalSheet {
            url
            fileName
            type
          }
        }
      }
    }
    compatibleRemotes: allProductsJson(
      filter: {
        productSeries: { eq: $remoteCompatibility }
        subcategory: { eq: "Wireless Remotes" }
      }
    ) {
      nodes {
        _id
        category
        capacity
        capacityText
        name
        productId
        productSeries
        subcategory
        description
        cardFeatures
      }
    }
    compatibleControls: allProductsJson(
      filter: {
        subcategory: { eq: $controlsCompatibility, ne: "Wireless Remotes" }
      }
    ) {
      nodes {
        _id
        category
        capacity
        capacityText
        name
        productId
        productSeries
        subcategory
        description
        cardFeatures
      }
    }
    controlsImages: allImageSharp(
      filter: { original: { src: { regex: "/.*controls/i" } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...squareFluidImage
          }
        }
      }
    }
  }
`

export default SkyAir
